export default class JavascriptChallenge {
    constructor(private formElement: HTMLFormElement) {}

    setHiddenChallengeValue(challengeValue: string) {
        const challengeInput = document.createElement("input")
        challengeInput.type = "hidden"
        challengeInput.id = "challengeValue"
        challengeInput.name = "challengeValue"
        challengeInput.value = challengeValue
        this.formElement.appendChild(challengeInput)
    }
}
